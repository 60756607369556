import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

export const Head = () => <Seo title="Mentions légales" noindex />

const MentionsPage = () => (
  <Layout content="page">
    <h1>Mentions légales</h1>
    <h2>Informations éditoriales</h2>
    <p>
      JPCA – Paris asiatique
      <br />
      SIRET : 792 650 681 000 36
      <br />
      7 rue Henri René
      <br />
      34 000 Montpellier
    </p>
    <h2>Directeur de publication</h2>
    <p>
      J. Pouchin
      <br />
      Adresse électronique :{" "}
      <a
        href="mailto:joachim@parisasiatique.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        joachim@parisasiatique.com
      </a>
      <br />
      Téléphone : +33645221298
    </p>
    <h2>Droits d’auteur</h2>
    <p>
      © {new Date().getFullYear()} Paris asiatique – Toute reproduction
      interdite sans autorisation expresse et écrite. Des extraits de 128 mots
      maximum et des liens peuvent être utilisés, si Paris asiatique et
      Parisasiatique.com sont clairement mentionnés comme auteur avec renvoi de
      lien vers la source.
    </p>
    <h2>Politique de confidentialité</h2>
    <h3>Cookies</h3>
    <h4>Données statistiques</h4>
    <p>
      Ces données permettent de savoir ce qui intéresse les utilisateurs. Elles
      sont anonymisées et ne sont pas conservées au-delà de 14 mois à compter de
      la première utilisation.
    </p>
    <h4>Contenus</h4>
    <p>
      Ces cookies tiers sont utilisés pour proposer des vidéos et des boutons de
      partage sur les réseaux sociaux.
    </p>
    <p>
      Si vous refusez l’installation de cookies sur votre terminal, vous pouvez
      les bloquer via votre navigateur, via une extension ou au cas par cas. La
      CNIL fournit un{" "}
      <a
        href="https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser"
        target="_blank"
        rel="noopener noreferrer"
      >
        guide pratique
      </a>{" "}
      pour le faire facilement.
    </p>
    <h3>Protection des données personnelles</h3>
    <h4>Formulaire de contact</h4>
    <p>
      Les informations recueillies sur le formulaire de contact sont
      enregistrées dans un fichier informatisé par Paris asiatique pour la
      gestion de sa clientèle. Elles sont conservées pendant un an et sont
      destinées à l’éditeur du service.
    </p>

    <p>
      Conformément à la loi « informatique et libertés », vous pouvez exercer
      votre droit d’accès aux données vous concernant et les faire rectifier en
      nous contactant sur{" "}
      <a
        href="mailto:joachim@parisasiatique.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        joachim@parisasiatique.com
      </a>
      .
    </p>
    <h2>Crédits</h2>
    <p>
      Réalisation :{" "}
      <a
        title="Solygambas"
        href="https://bit.ly/solygambas"
        target="_blank"
        rel="noopener noreferrer"
      >
        Solygambas
      </a>
    </p>
    <p>
      Photos : fournies en{" "}
      <a
        title="Licence CC BY 4.0 FR"
        href="https://creativecommons.org/licenses/by/4.0/deed.fr"
        target="_blank"
        rel="noopener noreferrer"
      >
        licence CC BY 4.0 FR
      </a>{" "}
      par Flickr et adaptées.
    </p>
    <p>
      Hébergement :{" "}
      <a
        title="Netlify, Inc."
        href="https://www.netlify.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Netlify, Inc.
      </a>
      , ayant son siège social au 2325 3rd Street, Suite 215, San Francisco,
      California 94107, United States of America.
    </p>
    <p>
      <Link to="/" title="Paris asiatique">
        Revenir à l'accueil
      </Link>
    </p>
  </Layout>
)

export default MentionsPage
